import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import StyleFormBox from "../../../../styledComponent/Form/StyleFormBox";
import Label from "../../../../styledComponent/Inputs/Label";
import CurrencyField from "../../../../styledComponent/Inputs/CurrencyField";
import SecandaryButton from "../../../../styledComponent/Button/SecandaryButton";
import PrimaryButton from "../../../../styledComponent/Button/PrimaryButton";
import FormDialogBox from "../../../DialogBox/FormDialogBox";
import DetailBalanceSheet from "./DetailBalanceSheet";
import { removeBlankKey } from "../../../../utils/helpers";
import {
  delProfitAndLossAction,
  getProfitAndLossAction,
  saveProfitAndLossAction,
} from "../../../../redux/itr/action";
import { toast } from "react-toastify";
import { RiDeleteBin6Fill } from "react-icons/ri";
import DeleteConfirmation from "../../../DialogBox/DeleteConfirmation";
export default function ProfitAndLoss() {
  const [balanceSheetConfirmBox, setBalanceSheetConfirmBox] = useState(false);
  const [detailedBalanceSheetForm, setDetailedBalanceSheetForm] =
    useState(false);
  const { strings, theme } = useSelector((s) => s.platformSetting);
  const { ItrData } = useSelector((s) => s.itr);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [pAndLData, setPAndLData] = useState({});
  const [expense, setExpense] = useState(0);
  const [income, setIncome] = useState(0);
  const [delBox, setDelBox] = useState(false);
  const [balanceId, setBalanceId] = useState();

  const saveData = () => {
    let sendData = removeBlankKey(pAndLData);
    setLoading(true);
    dispatch(
      saveProfitAndLossAction(sendData, (e) => {
        if (e.status == 200) {
          setBalanceId(e.data.id);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
    );
  };

  const delData = () => {
    dispatch(
      delProfitAndLossAction({ id: balanceId }, (e) => {
        if (e.status == 200) {
          getPAndL();
          setDelBox(false);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
    );
  };

  const getPAndL = () => {
    const data = `?itrId=${ItrData.id}`;
    dispatch(
      getProfitAndLossAction(data, (e) => {
        console.log("profitdata", e.data);
        if (e.status == 200) {
          setBalanceId(e?.data?.id || null);
          setPAndLData({
            purchaseValueFutures: Number(e.data.purchaseValueFutures) || "",
            purchaseValueOptions: Number(e.data.purchaseValueOptions) || "",
            purchaseValueCurrency: Number(e.data.purchaseValueCurrency) || "",
            purchaseValueCommodity: Number(e.data.purchaseValueCommodity) || "",
            otherBrokerageExp: Number(e.data.otherBrokerageExp) || "",
            turnoverFromOptions: Number(e.data.turnoverFromOptions) || "",
            turnoverFromFutures: Number(e.data.turnoverFromFutures) || "",
            turnoverFromCurrency: Number(e.data.turnoverFromCurrency) || "",
            turnoverFromCommodity: Number(e.data.turnoverFromCommodity) || "",
            itrId: ItrData.id,
          });
          setLoading(false);
        } else {
          toast.error(e.message);
          setLoading(false);
        }
      })
    );
  };

  useEffect(() => {
    getPAndL();
  }, []);

  useEffect(() => {
    let sumObj = removeBlankKey(pAndLData);
    const {
      purchaseValueFutures = 0,
      purchaseValueOptions = 0,
      purchaseValueCurrency = 0,
      purchaseValueCommodity = 0,
      otherBrokerageExp = 0,
    } = sumObj;

    setExpense(
      purchaseValueFutures +
        purchaseValueOptions +
        purchaseValueCurrency +
        purchaseValueCommodity +
        otherBrokerageExp
    );
    const {
      turnoverFromOptions = 0,
      turnoverFromFutures = 0,
      turnoverFromCurrency = 0,
      turnoverFromCommodity = 0,
    } = sumObj;

    setIncome(
      turnoverFromOptions +
        turnoverFromFutures +
        turnoverFromCurrency +
        turnoverFromCommodity
    );
  }, [pAndLData]);

  console.log("pAndLData", pAndLData);

  return (
    <div>
      <StyleFormBox>
        <h4>
          {
            strings.balance_sheet
              .run_sme_msme_business_details_to_provide_in_profit_loss
          }{" "}
        </h4>
        <SecandaryButton
          title={strings.button.detailed_profit_loss}
          handleClick={() => {
            setBalanceSheetConfirmBox(true);
          }}
        />
      </StyleFormBox>

      <StyleFormBox>
        {balanceId && (
          <button
            className="delete_button mb-2 block ml-auto"
            onClick={() => setDelBox(true)}
          >
            <RiDeleteBin6Fill />
          </button>
        )}
        <DeleteConfirmation
          open={delBox}
          handleClose={() => setDelBox(false)}
          hendleDelete={() => {
            delData();
          }}
        />
        <div className="flex items-center justify-between">
          <h4>{strings.balance_sheet.profit_income_expenses}</h4>
          <h4>{income - expense}</h4>
        </div>
        <hr />
        <StyleFormBox>
          <div className="flex items-center justify-between">
            <h4>{strings.balance_sheet.expenses}</h4>
            <h4>
              {" "}
              {strings.income.total_expenses} : {expense}
            </h4>
          </div>
          <p>
            {
              strings.balance_sheet
                .enter_details_expenses_incurred_business_trading_activities
            }
          </p>
          <div className="flex_input my-2">
            <label>{strings.balance_sheet.purchase_value_futures}</label>
            <CurrencyField
              error={""}
              value={pAndLData.purchaseValueFutures}
              handleChange={(e) => {
                setPAndLData({
                  ...pAndLData,
                  purchaseValueFutures: Number(e.target.value),
                });
              }}
            />
          </div>
          <div className="flex_input my-2">
            <label>{strings.balance_sheet.purchase_value_of_options}</label>
            <CurrencyField
              error={""}
              value={pAndLData.purchaseValueOptions}
              handleChange={(e) => {
                setPAndLData({
                  ...pAndLData,
                  purchaseValueOptions: Number(e.target.value),
                });
              }}
            />
          </div>
          <div className="flex_input my-2">
            <label>
              {strings.balance_sheet.purchase_value_of_currency_derivatives}
            </label>
            <CurrencyField
              error={""}
              value={pAndLData.purchaseValueCurrency}
              handleChange={(e) => {
                setPAndLData({
                  ...pAndLData,
                  purchaseValueCurrency: Number(e.target.value),
                });
              }}
            />
          </div>
          <div className="flex_input my-2">
            <label>
              {strings.balance_sheet.purchase_value_of_commodity_derivatives}
            </label>
            <CurrencyField
              error={""}
              value={pAndLData.purchaseValueCommodity}
              handleChange={(e) => {
                setPAndLData({
                  ...pAndLData,
                  purchaseValueCommodity: Number(e.target.value),
                });
              }}
            />
          </div>
          <div className="flex_input my-2">
            <label>{strings.balance_sheet.other_brokerage_expenses}</label>
            <CurrencyField
              error={""}
              value={pAndLData.otherBrokerageExp}
              handleChange={(e) => {
                setPAndLData({
                  ...pAndLData,
                  otherBrokerageExp: Number(e.target.value),
                });
              }}
            />
          </div>
        </StyleFormBox>
      </StyleFormBox>

      <StyleFormBox>
        <div className="flex items-center justify-between">
          <h4>{strings.balance_sheet.income}</h4>
          <h4>
            {strings.balance_sheet.total_income} : {income}
          </h4>
        </div>
        <p>
          {
            strings.balance_sheet
              .details_income_earned_from_business_professional_services_trading
          }
        </p>
        <hr />
        <div className="flex_input my-2">
          <label>{strings.balance_sheet.turnover_from_options}</label>
          <CurrencyField
            error={""}
            value={pAndLData.turnoverFromOptions}
            handleChange={(e) => {
              setPAndLData({
                ...pAndLData,
                turnoverFromOptions: Number(e.target.value),
              });
            }}
          />
        </div>
        <div className="flex_input my-2">
          <label>{strings.balance_sheet.turnover_from_futures}</label>
          <CurrencyField
            error={""}
            value={pAndLData.turnoverFromFutures}
            handleChange={(e) => {
              setPAndLData({
                ...pAndLData,
                turnoverFromFutures: Number(e.target.value),
              });
            }}
          />
        </div>
        <div className="flex_input my-2">
          <label>
            {strings.balance_sheet.turnover_from_currency_derivatives}
          </label>
          <CurrencyField
            error={""}
            value={pAndLData.turnoverFromCurrency}
            handleChange={(e) => {
              setPAndLData({
                ...pAndLData,
                turnoverFromCurrency: Number(e.target.value),
              });
            }}
          />
        </div>
        <div className="flex_input my-2">
          <label>
            {strings.balance_sheet.turnover_from_commodity_derivatives}
          </label>
          <CurrencyField
            error={""}
            value={pAndLData.turnoverFromCommodity}
            handleChange={(e) => {
              setPAndLData({
                ...pAndLData,
                turnoverFromCommodity: Number(e.target.value),
              });
            }}
          />
        </div>
      </StyleFormBox>

      <PrimaryButton
        title={strings.button.save_and_continue}
        addClass={"mt-5"}
        handleClick={saveData}
      />

      <FormDialogBox
        open={balanceSheetConfirmBox}
        width={"610px"}
        formTitle={
          strings.balance_sheet.are_you_see_you_want_to_fill_the_balance_sheet
        }
        handleClose={() => {
          setBalanceSheetConfirmBox(false);
        }}
      >
        <div>
          <h4 className="my-4">
            {strings.balance_sheet.applicable_only_you_have_these}
          </h4>
          <ul style={{ listStyleType: "disc" }} className="ml-4">
            <li>{strings.balance_sheet.reserves_surplus}</li>
            <li>{strings.balance_sheet.foreign_bank_loans}</li>
            <li>
              {strings.balance_sheet.finished_goods_inventories_other_assets}
            </li>
            <li>{strings.balance_sheet.sme_msme_businesses}</li>
            <li>
              {strings.balance_sheet.professions_with_revenue_above_lakhs}
            </li>
          </ul>
          <div className="flex gap-3 items-center mt-4">
            <SecandaryButton
              title={strings.button.go_back}
              handleClick={() => setBalanceSheetConfirmBox(false)}
            />
            <PrimaryButton
              title={strings.button.yes_fill_detailed}
              handleClick={() => {
                setDetailedBalanceSheetForm(true);
              }}
            />
          </div>
        </div>
      </FormDialogBox>

      <FormDialogBox
        open={detailedBalanceSheetForm}
        width={"700px"}
        formTitle={strings.balance_sheet.balance_sheet_for_itr3}
        handleClose={() => {
          setDetailedBalanceSheetForm(false);
        }}
      >
        <DetailBalanceSheet />
      </FormDialogBox>
    </div>
  );
}
