import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { MdFileOpen } from "react-icons/md";
import { FaFileUpload } from "react-icons/fa";
import { addOpacity } from "../../utils/helpers";
import { FiArrowRightCircle } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
export default function Service() {
  const { strings, theme } = useSelector((s) => s.platformSetting);
  const navigate = useNavigate();
  return (
    <Section theme={theme}>
      <div className="our_services_main">
        <div className="flex gap-3 items-center container_box">
          <div className="w-[33%] tax_services">
            <p style={{ color: theme.primary_bg }} className="text-base">
              Our Services
            </p>
            <h4 className="text-3xl font-bold">
              Looking for <span>Tax services</span> -we’re here to help
            </h4>
          </div>
          <div className="file_box w-[60%]">
            <h4 className="text-4xl">File your ITR yourself in 5 minutes</h4>
            <p className="text-lg font-semibold my-3">
            You can file your ITR on your own. It's Easy, quick & free to file with SamosaTax on your own.
            </p>
            <button onClick={() => navigate("/filing/itr/link_pan")}>
              File Now <FiArrowRightCircle />
            </button>
            <span>
              <img src="/images/file.png" />
            </span>
          </div>
        </div>
        <div className="section_tool flex gap-4 container_box ">
          <div>
            <div className="icon_box">
              <img src={"/Images/icon_upload.png"} />
            </div>
            <h4>Upload Form 16</h4>
            <p>
              Simplify the Income Tax Return filing process at SamosaTax. Just
              upload your Form 16 and get the ITR prepared automatically.
            </p>
            <button onClick={() => navigate("/upload-form-16")}>
              Upload Now <FiArrowRightCircle />
            </button>
          </div>
          <div>
            <div className="icon_box">
              <img src={"/Images/icon-tpo.png"} />
            </div>
            <h4>Tax Optimizer Tool</h4>
            <p>
              Maximize savings effortlessly with our Tax Optimizer Tool.
              Streamline your financial strategy and unlock a more efficient tax
              approach.
            </p>
            <button onClick={() => navigate("/tax-tool")}>
              Explore Now <FiArrowRightCircle />
            </button>
          </div>
          <div>
            <div className="icon_box">
              <img src={"/Images/icon-eca.png"} />
            </div>
            <h4>Get an Expert Assistance </h4>
            <p>
              Step into a world of personalized solutions for your Tax filing.
              Get a Personal CA for exclusive guidance and expert advice for
              your goals.
            </p>
            <button>
              Connect Now<FiArrowRightCircle />
            </button>
          </div>
        </div>
      </div>
    </Section>
  );
}
const Section = styled.section`
  margin: 100px auto;
  max-width: 1200px;

  @media (min-width: 320px) and (max-width: 1440px) {
    margin: 30px auto;
  }

  .file_box {
    background-color: ${(p) => p.theme.primary_bg};
    padding: 30px 16% 30px 30px;
    border-radius: 20px;
    color: ${(p) => p.theme.secandary_txt};
    margin-right: 100px;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    > span {
      position: absolute;
      top: 50%;
      right: -36%;
      transform: translate(-50%, -50%);
      font-size: 130px;
      width: 300px;
    }
    > button {
      background-color: ${(p) => p.theme.secandary_bg};
      color: ${(p) => p.theme.primary_txt};
      padding: 6px 40px;
      border-radius: 50px;
      margin-top: 10px;
      font-weight: 600;
      display: flex;
      align-items: center;
      gap: 10px;
      > svg {
        color: ${(p) => p.theme.primary_bg};
      }
    }
    @media (min-width: 320px) and (max-width: 1024px) {
      width: 100%;
    }
    img {
      @media (min-width: 320px) and (max-width: 1024px) {
        display: none;
      }
    }
  }

  .section_tool {
    margin-top: 60px;
    > div {
      border-radius: 10px;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      padding: 10px;
      .icon_box {
        background-color: ${(p) => addOpacity(p.theme.primary_txt, 0.1)};
        width: fit-content;
        padding: 6px;
        font-size: 30px;
        border-radius: 14px;
        margin-bottom: 18px;
      }

      > h4 {
        font-size: 30px;
        font-weight: 700;
        opacity: 0.9;
      }
      > p {
        font-size: 16px;
        margin: 10px 0px;
      }
      > button {
        background-color: ${(p) => p.theme.primary_bg};
        padding: 6px 20px;
        border-radius: 50px;
        font-weight: 600;
        color: ${(p) => p.theme.secandary_txt};
        display: flex;
        gap: 10px;
        align-items: center;
        font-size: 18px;
      }
      @media (min-width: 320px) and (max-width: 768px) {
        margin-top: 20px;
      }
      @media (min-width: 320px) and (max-width: 1024px) {
        margin-bottom: 20px;
      }
    }

    @media (min-width: 320px) and (max-width: 768px) {
      display: block;
    }
  }

  .container_box {
    @media (min-width: 320px) and (max-width: 1024px) {
      display: block;
    }
    .tax_services {
      h4 {
        font-size: 42px;
        span {
          color: #ffa53b;
        }
      }
      @media (min-width: 320px) and (max-width: 1024px) {
        width: 100%;
        text-align: center;
        margin-bottom: 10px;
      }
    }
  }
`;
