import React, { useEffect, useState } from "react";
import StyleFormBox from "../../../../styledComponent/Form/StyleFormBox";
import Input from "../../../../styledComponent/Inputs/Input";
import {  useSelector } from "react-redux";
import {  Formik } from "formik";
import * as Yup from "yup";
import LoadingBox from "../../../DialogBox/LoadingBox";

export default function RentalProperty({
  handleCallback,
  formsData,
}) {
  const [formData, setFormData] = useState({});
  const [loader, setLoader] = useState(true);
  const { strings } = useSelector((s) => s.platformSetting);

  const validationSchema = Yup.object().shape({
    interestPaidOnHL: Yup.number().moreThan(
      0,
      strings.validation_message.amount_greater_than_0
    ),
    unrealizedRent: Yup.number().moreThan(
      0,
      strings.validation_message.amount_greater_than_0
    ),
    municipalTaxes: Yup.number().moreThan(
      0,
      strings.validation_message.amount_greater_than_0
    ),
    annualRentReceived: Yup.number().required(strings.validation_message.required).moreThan(
      0,
      strings.validation_message.amount_greater_than_0
    ),
  });

  useEffect(() => {
    setLoader(true);
    setFormData({
      propertyType: 101,
      annualRentReceived: formsData?.annualRentReceived || '',
      municipalTaxes: formsData?.municipalTaxes || '',
      interestPaidOnHL: formsData?.interestPaidOnHL || '',
      unrealizedRent: formsData?.unrealizedRent || '',
    });
    setLoader(false);
  }, [formsData]);

  console.log('formData',formData)

  return (
    <div>
     <LoadingBox open={loader} />

     <Formik
          initialValues={formData}
          validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={(values) => {
            handleCallback(values);
          }}
        >
          {({ values, errors, handleChange, handleSubmit }) => (
            <div>
              <StyleFormBox>
                <h4>{strings.income.income_details} *</h4>
                <div className="flex_input my-2">
                  <label>{strings.income.annual_rent_recieved} *</label>
                  <Input
                    inputType={"currency"}
                    name={"annualRentReceived"}
                    error={errors.annualRentReceived}
                    value={values.annualRentReceived}
                    onChange={handleChange}
                  />
                </div>
                <div className="flex_input my-2">
                  <label>{strings.income.municipal_tax}</label>
                  <Input
                    inputType={"currency"}
                    name={"municipalTaxes"}
                    error={errors.municipalTaxes}
                    value={values.municipalTaxes}
                    onChange={handleChange}
                  />
                </div>
                <div className="flex_input my-2">
                  <label>
                    {
                      strings.income
                        .interest_paid_on_loan_for_this_house_property
                    }
                  </label>
                  <Input
                    inputType={"currency"}
                    name={"interestPaidOnHL"}
                    error={errors.interestPaidOnHL}
                    value={values.interestPaidOnHL}
                    onChange={handleChange}
                  />
                </div>
              </StyleFormBox>
              <StyleFormBox>
              <h4>{strings.income.unrealized_rent}</h4>
              <div className="flex_input my-2">
                <label>{strings.income.arrears_unrealized_rent_received}</label>
                <Input
                  name={"unrealizedRent"}
                  inputType={"currency"}
                  error={errors.unrealizedRent}
                  value={values.unrealizedRent}
                  onChange={handleChange}
                />
              </div>
            </StyleFormBox>

              <button className="primary_button my-4" onClick={handleSubmit}>
                {strings.button.save_and_close}
              </button>
            </div>
          )}
        </Formik>
    </div>
  );
}
