import React, { useEffect, useState } from "react";
import AccordianBox from "../../../../styledComponent/Form/AccordianBox";
import { useDispatch, useSelector } from "react-redux";
import { addOpacity } from "../../../../utils/helpers";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin6Fill } from "react-icons/ri";
import FormDialogBox from "../../../DialogBox/FormDialogBox";
import Select from "../../../../styledComponent/Inputs/Select";
import Income44ADA from "./Income44ADA";
import {
  deleteIncome44AD,
  deleteIncome44ADA,
  getProfessionalBusiness,
  getProfessionalBusiness3,
  saveIncome44AD,
  saveIncome44ADA,
} from "../../../../redux/itr/action";
import { toast } from "react-toastify";
import Income44AD from "./Income44AD";
import Section44AE from "./Section44AE";
import LoadingBox from "../../../DialogBox/LoadingBox";
import FinancialParticular from "./FinancialParticular";
import AddGst from "./AddGst";
import {useNavigate } from "react-router-dom";

export default function ProfessionalIncome({ handleCallback, formsData }) {
  const [professionalIncomeForm, setProfessionalIncomeForm] = useState(false);
  const [financialParticularForm, setFinancialParticularForm] = useState(false);
  const [gstFormBox, setGstFormBox] = useState(false);
  const [optionData, setOptionData] = useState({});
  const [activeOption, setActiveOption] = useState();
  const [editFormData, setEditFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const { strings, theme, categories } = useSelector((s) => s.platformSetting);
  const { ItrData } = useSelector((s) => s.itr);
  const [propertyObj, setPropertyObj] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate()

  // const propertyObj = [
  //   { id: 1, name: strings.income.income_under_44ada },
  //   { id: 2, name: strings.income.income_under_44ad },
  //   { id: 3, name: strings.income.carriage_under_44ae },
  // ];

  const getProfessionalBusinessApi = async () => {
    // setLoading(true);
    let parm = `?itrId=${ItrData.id}`;
    dispatch(
      getProfessionalBusiness(parm, (e) => {
        if (e.status == 200) {
          setOptionData(e.data);
          setPropertyObj(e?.data?.businessIncomeType?.slice(0,3))
          setActiveOption(e?.data?.businessIncomeType?.slice(0,3)[0])
          setLoading(false);
        } else {
          toast.error(e.message);
          setLoading(false);
        }
      })
    );
  };

  const getProfessionalBusiness3Api = async () => {
    // setLoading(true);
    let parm = `?itrId=${ItrData.id}`;
    dispatch(
      getProfessionalBusiness3(parm, (e) => {
        if (e.status == 200) {
          setOptionData(e.data);
          setPropertyObj(e?.data?.businessIncomeType?.slice(3,5))
          setActiveOption(e?.data?.businessIncomeType?.slice(3,5)[0])
          setLoading(false);
        } else {
          toast.error(e.message);
          setLoading(false);
        }
      })
    );
  };

  const saveSection44ADAApi = async (value) => {
    setLoading(true);
    dispatch(
      saveIncome44ADA(value, (e) => {
        console.log("saveSection44ADAApi =========>", e.data);
        if (e.status == 200) {
          setOptionData({
            ...optionData,
            businessProfessionIncome: {
              ...optionData.businessProfessionIncome,
              professionalIncome: e.data,
            },
          });

          setProfessionalIncomeForm(false);
          setLoading(false);
        } else {
          toast.error(e.message);
          setLoading(false);
        }
      })
    );
  };

  const saveSection44ADApi = async (value) => {
    setLoading(true);
    dispatch(
      saveIncome44AD(value, (e) => {
        console.log("saveSection44ADApi =========>", e);
        if (e.status == 200) {
          setOptionData({
            ...optionData,
            businessProfessionIncome: {
              ...optionData.businessProfessionIncome,
              businessIncome: e.data,
            },
          });
          setProfessionalIncomeForm(false);
          setLoading(false);
        } else {
          toast.error(e.message);
          setLoading(false);
        }
      })
    );
  };

  const deleteSection44ADAApi = async (id) => {
    setLoading(true);
    dispatch(
      deleteIncome44ADA({ id }, (e) => {
        if (e.status == 200) {
          setOptionData({
            ...optionData,
            businessProfessionIncome: {
              ...optionData.businessProfessionIncome,
              professionalIncome: null,
            },
          });
          setLoading(false);
        } else {
          // toast.error(e.message);
          setLoading(false);
        }
      })
    );
  };

  const deleteSection44ADApi = async (id) => {
    setLoading(true);
    dispatch(
      deleteIncome44AD({ id }, (e) => {
        if (e.status == 200) {
          setOptionData({
            ...optionData,
            businessProfessionIncome: {
              ...optionData.businessProfessionIncome,
              businessIncome: null,
            },
          });
          setLoading(false);
        } else {
          // toast.error(e.message);
          setLoading(false);
        }
      })
    );
  };

  const checkRedirection = () => {
    if(ItrData.form = 'ITR_3'){
      navigate('/form/filing/ITR_3/grossincome/professionalincome')
    }else{
      setProfessionalIncomeForm(true)
    }
  }

  useEffect(() => {
    if(ItrData.form == 'ITR_3'){
      getProfessionalBusiness3Api()
    }else{
      getProfessionalBusinessApi();
    }

  }, []);

  console.log("optionDat3333a", ItrData);

  return (
    <>
      <LoadingBox open={loading} />
      <AccordianBox
        title={strings.income.professional_freelencing_business_income}
        description={strings.income.for_all_professionals}
      >
        {optionData?.businessProfessionIncome?.professionalIncome && (
          <div
            className="flex gap-2 p-3 my-3 align-center"
            style={{
              background: addOpacity(theme.primary_bg, 0.2),
              borderRadius: "10px",
            }}
          >
            <h4 className="mr-auto">
              {strings.income.professional_income_total}
            </h4>
            <h4>
              {strings.label.rupee}
              {
                optionData?.businessProfessionIncome?.professionalIncome
                  ?.totalExpenses
              }
            </h4>
            <div className="action_buttons">
              <button
                className="edit_btn"
                onClick={() => {
                  setProfessionalIncomeForm(true);
                  setEditFormData(
                    optionData?.businessProfessionIncome?.professionalIncome
                  );
                  setActiveOption(147);
                }}
              >
                <FaRegEdit />
              </button>
              <button
                className="delete_btn"
                onClick={() =>
                  deleteSection44ADAApi(
                    optionData?.businessProfessionIncome?.professionalIncome?.id
                  )
                }
              >
                <RiDeleteBin6Fill />
              </button>
            </div>
          </div>
        )}

        {optionData?.businessProfessionIncome?.businessIncome && (
          <div
            className="flex gap-2 p-3 my-3 align-center"
            style={{
              background: addOpacity(theme.primary_bg, 0.2),
              borderRadius: "10px",
            }}
          >
            <h4 className="mr-auto">business Income</h4>

            <h4>
              {strings.label.rupee}{" "}
              {
                optionData?.businessProfessionIncome?.businessIncome
                  ?.totalExpenses
              }
            </h4>
            <div className="action_buttons">
              <button
                className="edit_btn"
                onClick={() => {
                  setProfessionalIncomeForm(true);
                  setEditFormData(
                    optionData?.businessProfessionIncome?.businessIncome
                  );
                  setActiveOption(148);
                }}
              >
                <FaRegEdit />
              </button>
              <button
                className="delete_btn"
                onClick={() =>
                  deleteSection44ADApi(
                    optionData?.businessProfessionIncome?.businessIncome?.id
                  )
                }
              >
                <RiDeleteBin6Fill />
              </button>
            </div>
          </div>
        )}

        <button
          className="secandary_button"
          onClick={checkRedirection}
        >
          {strings.button.add_entry}
        </button>
        <hr className="mt-4" />

        <div className="my-5">
          <h4>{strings.income.financial_particulars}</h4>
          <p>{strings.income.mandatory_presumptive_income}</p>
        </div>

        {[1].map((item, ix) => (
          <div
            className="flex gap-2 p-3 my-3 align-center"
            style={{
              background: addOpacity(theme.primary_bg, 0.2),
              borderRadius: "10px",
            }}
            key={ix}
          >
            <h4 className="mr-auto">title</h4>

            <h4>{item?.total}</h4>
            <div className="action_buttons">
              <button
                className="edit_btn"
                // onClick={() => setDividendIncome(true)}
              >
                <FaRegEdit />
              </button>
              <button
                className="delete_btn"
                // onClick={() =>
                // }
              >
                <RiDeleteBin6Fill />
              </button>
            </div>
          </div>
        ))}

        <button
          className="secandary_button"
          onClick={() => setFinancialParticularForm(true)}
        >
          {strings.button.add_entry}
        </button>

        <div className="flex items-center justify-between my-4">
          <h4>{strings.income.add_gst_details}</h4>
          <button className="add_button" onClick={() => setGstFormBox(true)}>
            {" "}
            {strings.button.add}
          </button>
        </div>

        {/* ---------------------------------------------- Professional, Freelancing and Business Income ------------------------------------------------- */}
        {/* <FormDialogBox
          open={professionalIncomeForm}
          formTitle={strings.income.professional_freelencing_business_income}
          handleClose={() => {
            setProfessionalIncomeForm(false);
          }}
        >
          <div className="mt-4">
            <div>
              <h4>{strings.income.choose_income_type}</h4>
              <Select
                value={activeOption}
                onChange={(e) => {
                  setActiveOption(e.target.value);
                }}
                className={"mt-2"}
              >
                {propertyObj?.map((item, ix) => (
                  <option value={item.id} key={ix}>
                    {item.name}
                  </option>
                ))}
              </Select>
            </div>

            {activeOption == 147 ? (
              <Income44ADA
                handleCallback={(e) => {
                  saveSection44ADAApi(e);
                }}
                formsData={editFormData}
                professionType={optionData.professionTypes}
              />
            ) : activeOption == 148 ? (
              <Income44AD
                handleCallback={(e) => {
                  saveSection44ADApi(e);
                }}
                formsData={editFormData}
                businessType={optionData.businessType}
              />
            ) : activeOption == 149 ? (
              <Section44AE
                data={optionData?.businessProfessionIncome?.businessDetail44AE}
                businessCode={optionData?.businessCodes}
                ownershipStatus={optionData?.ownershipStatus}
              />
            ) : (
              ""
            )}
          </div>
        </FormDialogBox> */}

        {/* --------------------Professional, Freelancing and Business Income for ITR 3----------------- */}


        {/* -----------------------------------------------  Financial Particulars ------------------------------------------------- */}
        <FormDialogBox
          open={financialParticularForm}
          formTitle={strings.income.financial_particulars}
          handleClose={() => {
            setFinancialParticularForm(false);
          }}
        >
          <FinancialParticular />
        </FormDialogBox>

        <FormDialogBox
          open={gstFormBox}
          formTitle={strings.income.add_gst_details}
          handleClose={() => {
            setGstFormBox(false);
          }}
        >
          <AddGst />
        </FormDialogBox>
      </AccordianBox>
    </>
  );
}
