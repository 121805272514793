import React, { useEffect, useState } from "react";
import AccordianBox from "../../../../styledComponent/Form/AccordianBox";
import { useDispatch, useSelector } from "react-redux";
import { addOpacity } from "../../../../utils/helpers";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin6Fill } from "react-icons/ri";
import FormDialogBox from "../../../DialogBox/FormDialogBox";
import Select from "../../../../styledComponent/Inputs/Select";
import Income44ADA from "./Income44ADA";
import {
  deleteIncome44AD,
  deleteIncome44ADA,
  getProfessionalBusiness,
  getProfessionalBusiness3,
  saveIncome44AD,
  saveIncome44ADA,
} from "../../../../redux/itr/action";
import { toast } from "react-toastify";
import Income44AD from "./Income44AD";
import Section44AE from "./Section44AE";
import LoadingBox from "../../../DialogBox/LoadingBox";
import FinancialParticular from "./FinancialParticular";
import AddGst from "./AddGst";
import RegularBusiness from "./RegularBusiness";

export default function ProfessionalIncome() {
  const [professionalIncomeForm, setProfessionalIncomeForm] = useState(false);
  const [financialParticularForm, setFinancialParticularForm] = useState(false);
  const [gstFormBox, setGstFormBox] = useState(false);
  const [optionData, setOptionData] = useState({});
  const [activeOption, setActiveOption] = useState();
  const [editFormData, setEditFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const { strings, theme, categories } = useSelector((s) => s.platformSetting);
  const { ItrData } = useSelector((s) => s.itr);
  const [propertyObj, setPropertyObj] = useState([]);
  const dispatch = useDispatch();


  const getProfessionalBusinessApi = async () => {
    // setLoading(true);
    let parm = `?itrId=${ItrData.id}`;
    dispatch(
      getProfessionalBusiness(parm, (e) => {
        if (e.status == 200) {
          setOptionData(e.data);
          setPropertyObj(e?.data?.businessIncomeType?.slice(0,3))
          setActiveOption(e?.data?.businessIncomeType?.slice(0,3)[0].id)
          setLoading(false);
        } else {
          toast.error(e.message);
          setLoading(false);
        }
      })
    );
  };

  const getProfessionalBusiness3Api = async () => {
    // setLoading(true);
    let parm = `?itrId=${ItrData.id}`;
    dispatch(
      getProfessionalBusiness3(parm, (e) => {
        if (e.status == 200) {
          setOptionData(e.data);
          setPropertyObj(e?.data?.businessIncomeType?.slice(0,5))
          setActiveOption(e?.data?.businessIncomeType?.slice(0,5)[0].id)
          setLoading(false);
        } else {
          toast.error(e.message);
          setLoading(false);
        }
      })
    );
  };

  const saveSection44ADAApi = async (value) => {
    setLoading(true);
    dispatch(
      saveIncome44ADA(value, (e) => {
        console.log("saveSection44ADAApi =========>", e.data);
        if (e.status == 200) {
          setOptionData({
            ...optionData,
            businessProfessionIncome: {
              ...optionData.businessProfessionIncome,
              professionalIncome: e.data,
            },
          });

          setProfessionalIncomeForm(false);
          setLoading(false);
        } else {
          toast.error(e.message);
          setLoading(false);
        }
      })
    );
  };

  const saveSection44ADApi = async (value) => {
    setLoading(true);
    dispatch(
      saveIncome44AD(value, (e) => {
        console.log("saveSection44ADApi =========>", e);
        if (e.status == 200) {
          setOptionData({
            ...optionData,
            businessProfessionIncome: {
              ...optionData.businessProfessionIncome,
              businessIncome: e.data,
            },
          });
          setProfessionalIncomeForm(false);
          setLoading(false);
        } else {
          toast.error(e.message);
          setLoading(false);
        }
      })
    );
  };

  const deleteSection44ADAApi = async (id) => {
    setLoading(true);
    dispatch(
      deleteIncome44ADA({ id }, (e) => {
        if (e.status == 200) {
          setOptionData({
            ...optionData,
            businessProfessionIncome: {
              ...optionData.businessProfessionIncome,
              professionalIncome: null,
            },
          });
          setLoading(false);
        } else {
          // toast.error(e.message);
          setLoading(false);
        }
      })
    );
  };

  const deleteSection44ADApi = async (id) => {
    setLoading(true);
    dispatch(
      deleteIncome44AD({ id }, (e) => {
        if (e.status == 200) {
          setOptionData({
            ...optionData,
            businessProfessionIncome: {
              ...optionData.businessProfessionIncome,
              businessIncome: null,
            },
          });
          setLoading(false);
        } else {
          // toast.error(e.message);
          setLoading(false);
        }
      })
    );
  };

  useEffect(() => {
    if(ItrData.form == 'ITR_3'){
      getProfessionalBusiness3Api()
    }else{
      getProfessionalBusinessApi();
    }
  }, []);


  return (
    <div className="mt-4 container_box">
    <div>
      <h4>{strings.income.choose_income_type}</h4>
      <Select
        value={activeOption}
        onChange={(e) => {
          setActiveOption(e.target.value);
        }}
        className={"mt-2"}
      >
        {propertyObj?.map((item, ix) => (
          <option value={item.id} key={ix}>
            {item.name}
          </option>
        ))}
      </Select>
    </div>

    {activeOption == 147 ? (
      <Income44ADA
        handleCallback={(e) => {
          saveSection44ADAApi(e);
        }}
        formsData={editFormData}
        professionType={optionData.professionTypes}
      />
    ) : activeOption == 148 ? (
      <Income44AD
        handleCallback={(e) => {
          saveSection44ADApi(e);
        }}
        formsData={editFormData}
        businessType={optionData.businessType}
      />
    ) : activeOption == 149 ? (
      <Section44AE
        data={optionData?.businessProfessionIncome?.businessDetail44AE}
        businessCode={optionData?.businessCodes}
        ownershipStatus={optionData?.ownershipStatus}
      />
    ) : activeOption== 625 ? (
      <RegularBusiness
       data = {optionData?.professionalBusiness}
       businessType = {optionData?.businessType}
      />
    ):""}
  </div>
  )
}
