import React from 'react'
import { useSelector } from 'react-redux';
import Label from '../../styledComponent/Inputs/Label';
import PasswordField from '../../styledComponent/Inputs/PasswordField';
import PrimaryButton from '../../styledComponent/Button/PrimaryButton';

export default function ResetPassword() {
    const { strings, theme } = useSelector((s) => s.platformSetting);
  return (
    <div>
        <h4>{strings.signUp.reset_password}</h4>
        <div className="text-left">
            <Label label={strings.signUp.old_password} />
            <PasswordField
              placeholder={strings.signUp.old_password}
              error={""}
              value={""}
              handleChange={() => {}}
            />
            <Label label={strings.signUp.new_password} />
            <PasswordField
              placeholder={strings.signUp.new_password}
              error={""}
              value={""}
              handleChange={() => {}}
            />
            <Label label={strings.label.confirm_password} />
            <PasswordField
              placeholder={strings.label.confirm_password}
              error={""}
              value={""}
              handleChange={() => {}}
            />
             <PrimaryButton
            title={strings.signUp.reset_password}
            handleClick={() => {
            }}
          />
          </div>
    </div>
  )
}
