const ApiEndPoint = "https://nft.sdnatech.com/samosataxapi";

const Urls = {
  userTheme: `${ApiEndPoint}/user/getUserTheme`,

  // User Authenticate
  userRegistration: `${ApiEndPoint}/auth/userRegistration`,
  updateUser: `${ApiEndPoint}/user/updateProfile`,
  userLogin: `${ApiEndPoint}/auth/userLogin`,
  userSocialLogin: `${ApiEndPoint}/auth/socialLogin`,
  userLogout: `${ApiEndPoint}/user/userLogout`,
  sendVerificationOtp: `${ApiEndPoint}/auth/sendVerificationOtp`,
  verificationOtp: `${ApiEndPoint}/auth/verifyOtp`,
  forgotPassword: `${ApiEndPoint}/auth/forgetPassword`,
  verifyOtpForgotPassword: `${ApiEndPoint}/auth/forgetPassOtpVerify`,
  resetPassword: `${ApiEndPoint}/auth/resetPassword`,

  // Platform Settings
  getStates: `${ApiEndPoint}/settings/getState`,
  getCitiesById: `${ApiEndPoint}/settings/getCities?stateId=`,
  getCategories: `${ApiEndPoint}/settings/getCategories`,
  getLanguages: `${ApiEndPoint}/settings/fetchLanguages`,

  getQuestions: `${ApiEndPoint}/question/fetchQuestions`,
  submitAnswer: `${ApiEndPoint}/question/submitAnswer`,

  // ITR
  getItrStatus: `${ApiEndPoint}/itr/list`,
  getPersonalInfo: `${ApiEndPoint}/itr/getPersonalInfo?itrId=`,
  setPersonalInfo: `${ApiEndPoint}/itr/personalInfo`,
  getGrossIncome: `${ApiEndPoint}/itr/grossIncome?itrId=`,
  setSalaryBreakup: `${ApiEndPoint}/itr/userSalaryBreakup`,
  getSalaryBreakup: `${ApiEndPoint}/itr/getUserSalaryBreakup?itrid=`,
  setOtherIncomeSource: `${ApiEndPoint}/itr/saveOtherIncomeSource`,
  deleteOtherIncomeSource: `${ApiEndPoint}/itr/deleteOtherIncomeSource`,
  getOtherIncomeSource: `${ApiEndPoint}/itr/getOtherIncomeSource?itrId=`,

  // House Income Source
  getHousePropertyIncome: `${ApiEndPoint}/housepropertyincome/fetch`,
  setHousePropertyIncome: `${ApiEndPoint}/housepropertyincome/save`,
  updateHousePropertyIncome: `${ApiEndPoint}/housepropertyincome/update`,
  deleteHousePropertyIncome: `${ApiEndPoint}/housepropertyincome/delete`,

  // Tax Saving
  addDonations: `${ApiEndPoint}/itr/addDonations`,
  updateDonations: `${ApiEndPoint}/itr/updateDonation`,
  deleteDonations: `${ApiEndPoint}/itr/deleteDonation`,

  getDeduction: `${ApiEndPoint}/itr/getDeductions?itrId=`,
  saveDeduction: `${ApiEndPoint}/itr/saveDeductions`,

  // Non Salary TDS
  saveTdsNonSalary: `${ApiEndPoint}/itr/saveTDSNonSalary`,
  updateTdsNonSalary: `${ApiEndPoint}/itr/updateTDSNonSalary`,
  deleteTdsNonSalary: `${ApiEndPoint}/itr/deleteTDSNonSalary`,

  // TDS on Sale/Rent of Immovable Property
  saveTDSImmovableProperty: `${ApiEndPoint}/itr/saveTDSImmovableProperty`,
  updateTDSImmovableProperty: `${ApiEndPoint}/itr/updateTDSImmovableProperty`,
  deleteTDSImmovableProperty: `${ApiEndPoint}/itr/deleteTDSImmovableProperty`,

  // Taxes collected at source
  saveTCS: `${ApiEndPoint}/itr/saveTCS`,
  updateTCS: `${ApiEndPoint}/itr/updateTCS`,
  deleteTCS: `${ApiEndPoint}/itr/deleteTCS`,

  // Self tax payments
  saveTaxPaidChallan: `${ApiEndPoint}/itr/saveTaxPaidChallan`,
  updateTaxPaidChallan: `${ApiEndPoint}/itr/updateTaxPaidChallan`,
  deleteTaxPaidChallan: `${ApiEndPoint}/itr/deleteTaxPaidChallan`,

  // Other APIS
  getGlossary: `${ApiEndPoint}/glossary/getGlossary`,
  getGlossaryById: `${ApiEndPoint}/glossary/getGlossaryById?id=`,
  getFaq: `${ApiEndPoint}/faq/getFaq`,
  getTicketTags: `${ApiEndPoint}/tickets/fetchTicketTags`,
  createTicket: `${ApiEndPoint}/tickets/create`,
  serviceList: `${ApiEndPoint}/service/list`,
  contactUs: `${ApiEndPoint}/settings/contactUs`,

  // CAPITAL GAIN APIS   =======================

  getCapitalGain: `${ApiEndPoint}/capitalgain/getCapitalGain?itrId=`,
  deleteCapitalGain: `${ApiEndPoint}/capitalgain/deleteCapitalGainItems`,

  saveStocks: `${ApiEndPoint}/capitalgain/addStocks`,
  updateStocks: `${ApiEndPoint}/capitalgain/updateStocks`,

  addSaleOfLand: `${ApiEndPoint}/capitalgain/addSaleOfLand`,
  updateSaleOfLand: `${ApiEndPoint}/capitalgain/updateSaleOfLand`,

  // Bonds & Debentures ============================
  addBondDebentures: `${ApiEndPoint}/capitalgain/addBondsAndDebenture`,
  updateBondDebentures: `${ApiEndPoint}/capitalgain/updateBondsAndDebenture`,

  // Deemed Capital Gains =======================================
  addDeemedCapitalGain: `${ApiEndPoint}/capitalgain/addDeemedCapitalGain`,
  updateDeemedCapitalGain: `${ApiEndPoint}/capitalgain/updateDeemedCapitalGain`,

  // Add Any Other Assets =============================================
  anyOtherAssets: `${ApiEndPoint}/capitalgain/anyOtherAssets`,
  updateAnyOtherAssets: `${ApiEndPoint}/capitalgain/updateAnyOtherAssets`,

  // Add Members =================================
  addMembers: `${ApiEndPoint}/itr/addMembers`,
  getMembers: `${ApiEndPoint}/itr/getMembers`,
  deleteMembers: `${ApiEndPoint}/itr/deleteMember`,
  updateMembers: `${ApiEndPoint}/itr/updateMembers`,

  // Professional, Freelancing and Business Income =====================================

  getProfessionalBusiness: `${ApiEndPoint}/businessincome/getProfessionalBusiness`,
  getProfessionalBusiness3: `${ApiEndPoint}/businessincomeitr3/getProfessionalIncomeITR3`,

  saveDetailOfProfessionBusiness: `${ApiEndPoint}/businessincomeitr3/saveDetailsOfProfession`,
  updateDetailOfProfessionBusiness: `${ApiEndPoint}/businessincomeitr3/updateDetailsOfProfession`,
  delDetailOfProfessionBusiness: `${ApiEndPoint}/businessincomeitr3/delDetailsOfProfession`,
  getDetailOfProfessionBusiness: `${ApiEndPoint}/businessincomeitr3/getDetailsOfProfession`,

  getBalanceSheet: `${ApiEndPoint}/businessincomeitr3/getBalanceSheet`,
  saveBalanceSheet: `${ApiEndPoint}/businessincomeitr3/saveBalanceSheet`,
  delBalancesheet: `${ApiEndPoint}/businessincomeitr3/delBalanceSheet`,

  getProfitAndLoss: `${ApiEndPoint}/businessincomeitr3/getProfitAndLoss`,
  saveProfitAndLoss: `${ApiEndPoint}/businessincomeitr3/saveProfitAndLoss`,
  delProfitAndLoss: `${ApiEndPoint}/businessincomeitr3/delProfitAndLoss`,

  saveProfessionalIncome44ADA: `${ApiEndPoint}/businessincome/saveProfessionalIncome44ADA`,
  saveProfessionalIncome44AD: `${ApiEndPoint}/businessincome/saveBusinessIncome44AD`,

  delProfessionalIncome44ADA: `${ApiEndPoint}/businessincome/delProfessionalIncome44ADA`,
  delProfessionalIncome44AD: `${ApiEndPoint}/businessincome/delBusinessIncome44AD`,

  // ======== Business Details =========================

  saveBusinessDetails44AE: `${ApiEndPoint}/businessincome/saveBusinessDetails44AE`,
  delBusinessDetails44AE: `${ApiEndPoint}/businessincome/delBusinessDetails44AE`,
  editBusinessDetails44AE: `${ApiEndPoint}/businessincome/editBusinessDetails44AE`,

  //  ====================== Goods Carriage Details =================

  saveGoodsCarriage: `${ApiEndPoint}/businessincome/saveGoodsCarriages`,
  delDelGoodsCarriage: `${ApiEndPoint}/businessincome/delGoodsCarriage`,
  editEditGoodsCarriage: `${ApiEndPoint}/businessincome/editGoodsCarriage`,

  // ================================== Financial Particulars =====================

  saveLiabilitiesAndAssets: `${ApiEndPoint}/businessincome/saveLiabilitiesAndAssets`,
  getLiabilitiesAndAssets: `${ApiEndPoint}/businessincome/getLiabilitiesAndAssets`,

  // ==================================== Add GST Details ============================
  saveaddGSTDetails: `${ApiEndPoint}/businessincome/addGSTDetails`,

  // ===================================  Carry Forward of Losses  =========================

  getBroughtFrowardLosses: `${ApiEndPoint}/itr/getBroughtFrowardLosses?itrId=`,

  savePreviousYearLosses: `${ApiEndPoint}/itr/savePreviousYearLosses`,
  deletePreviousYearLosses: `${ApiEndPoint}/itr/delPreviousYearLosses`,
  editPreviousYearLosses: `${ApiEndPoint}/itr/updatePreviousYearLosses`,

  saveDepreciationLoss: `${ApiEndPoint}/itr/saveDepreciationLoss`,
  deleteDepreciationLoss: `${ApiEndPoint}/itr/delDepreciationLoss`,
  editDepreciationLoss: `${ApiEndPoint}/itr/updateDepreciationLoss`,

  saveFilingDueDate: `${ApiEndPoint}/itr/saveFilingDueDate`,
  delFilingDueDate: `${ApiEndPoint}/itr/delFilingDueDate`,
  updateFilingDueDate: `${ApiEndPoint}/itr/updateFilingDueDate`,
};
export default Urls;
