import { useSelector } from "react-redux";
import styled from "styled-components";
import StyleFormBox from "../styledComponent/Form/StyleFormBox";
import { FaCloudUploadAlt } from "react-icons/fa";
import { addOpacity } from "../utils/helpers";

export default function UploadForm16() {
  const { strings, theme } = useSelector((s) => s.platformSetting);

  return (
    <Root theme={theme}>
      <h4 className="upload_heading">
        {strings.home.upload_form} <span>16</span>
      </h4>

      <div className="form_cotainer">
        <div className="upload_cloud text-5xl  p-8 rounded-full mx-auto">
          <FaCloudUploadAlt />
        </div>
        <p className="my-4">{strings.form.auto_fetch_details_upload_form_16}</p>
        <h4 className="">{strings.form.choose_your_form_16}</h4>
        <p className="my-4">{strings.form.upload_password_protected_pdf}</p>
        <div className="first_box center mb-3">
          {strings.home.upload_form_description}
        </div>
        <div className="upload_form_box">
          <input type="file" accept=".pdf" />
          <span>{strings.form.choose_your_form_16}</span>
        </div>
        <button className="primary_button">{strings.button.submit}</button>
      </div>
    </Root>
  );
}

const Root = styled.section`
  .upload_form {
    max-width: 500px;
    margin: auto;
    text-align: center;
  }
  .upload_heading {
    margin-top: 50px;
    font-size: 28px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    span {
      color: ${(p) => p.theme.primary_bg};
    }
  }
  .upload_cloud {
    width: fit-content;
    background-color: ${(p) => addOpacity(p.theme.primary_bg, 0.2)};
  }
  .form_cotainer {
    text-align: center;
    max-width: 800px;
    width: 90%;
    margin: 40px auto;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
      rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
      rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    padding: 50px 20px;
    border-radius: 10px;
    .first_box {
      background-color: ${(p) => addOpacity(p.theme.primary_bg, 0.2)};
      border-radius: 10px;
      padding: 20px;
      font-size: 20px;
      font-weight: 600;
    }
  }
  .upload_form_box {
    position: relative;
    border: 1px solid ${(p) => p.theme.border_clr};
    color: ${(p) => p.theme.primary_txt};
    background: ${(p) => p.theme.secandary_bg};
    border-radius: 12px;
    overflow: hidden;
    margin: 20px 0px;
    > input {
      border: none;
      background: none;
      z-index: 9;
      margin: 0px;
      opacity: 0;
      position: relative;
      cursor: pointer;
    }
    > span {
      z-index: 1;
      position: absolute;
      left: 50%;
      top: 50%;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transform: translate(-50%, -50%);
    }
  }
`;
