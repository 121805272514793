import React, { useEffect, useState } from 'react'
import SalaryIncome from '../../CommonScreens/GrossIncome/SalaryIncome'
import { useDispatch, useSelector } from 'react-redux';
import { deleteIncome44AD, deleteIncome44ADA, getGrossIncomeAction, getProfessionalBusiness, saveIncome44AD, saveIncome44ADA } from '../../../redux/itr/action';
import AccordianBox from '../../../styledComponent/Form/AccordianBox';
import { toast } from 'react-toastify';
import { addOpacity } from '../../../utils/helpers';
import { FaRegEdit } from 'react-icons/fa';
import { RiDeleteBin6Fill } from 'react-icons/ri';
import FormDialogBox from '../../DialogBox/FormDialogBox';
import Select from '../../../styledComponent/Inputs/Select';
import Income44AD from '../../CommonScreens/GrossIncome/ProfessionalIncome/Income44AD';
import Income44ADA from '../../CommonScreens/GrossIncome/ProfessionalIncome/Income44ADA';
import Section44AE from '../../CommonScreens/GrossIncome/ProfessionalIncome/Section44AE';
import FinancialParticular from '../../CommonScreens/GrossIncome/ProfessionalIncome/FinancialParticular';
import AddGst from '../../CommonScreens/GrossIncome/ProfessionalIncome/AddGst';
import CapitalGainIncome from '../../CommonScreens/GrossIncome/CapitalGainIncome';
import ProfessionalIncome from '../../CommonScreens/GrossIncome/ProfessionalIncome';
import OtherSourceIncome from '../../CommonScreens/GrossIncome/OtherSourceIncome';
import HousePropertyIncome from '../../CommonScreens/GrossIncome/HousePropertyIncome';
import styled from 'styled-components';


export default function Index({handleNext}) {

  const [professionalIncomeForm, setProfessionalIncomeForm] = useState(false);
  const [financialParticularForm, setFinancialParticularForm] = useState(false);
  const [gstFormBox, setGstFormBox] = useState(false);
  const [optionData, setOptionData] = useState({});
  const [activeOption, setActiveOption] = useState(3);
  const [editFormData, setEditFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const { strings, categories } = useSelector((s) => s.platformSetting);
  const { ItrData } = useSelector((s) => s.itr);
  const dispatch = useDispatch();

  const propertyObj = [
    { id: 1, name: strings.income.income_under_44ada },
    { id: 2, name: strings.income.income_under_44ad },
    { id: 3, name: strings.income.carriage_under_44ae },
  ];

  const getProfessionalBusinessApi = async () => {
    setLoading(true);
    let parm = `?itrId=${ItrData.id}`;
    dispatch(
      getProfessionalBusiness(parm, (e) => {
        if (e.status == 200) {
          setOptionData(e.data);
          setLoading(false);
        } else {
          toast.error(e.message);
          setLoading(false);
        }
      })
    );
  };

  const saveSection44ADAApi = async (value) => {
    setLoading(true);
    dispatch(
      saveIncome44ADA(value, (e) => {
        console.log("saveSection44ADAApi =========>", e.data);
        if (e.status == 200) {
          setOptionData({
            ...optionData,
            businessProfessionIncome: {
              ...optionData.businessProfessionIncome,
              professionalIncome: e.data,
            },
          });

          setProfessionalIncomeForm(false);
          setLoading(false);
        } else {
          toast.error(e.message);
          setLoading(false);
        }
      })
    );
  };

  const saveSection44ADApi = async (value) => {
    setLoading(true);
    dispatch(
      saveIncome44AD(value, (e) => {
        console.log("saveSection44ADApi =========>", e);
        if (e.status == 200) {
          setOptionData({
            ...optionData,
            businessProfessionIncome: {
              ...optionData.businessProfessionIncome,
              businessIncome: e.data,
            },
          });
          setProfessionalIncomeForm(false);
          setLoading(false);
        } else {
          toast.error(e.message);
          setLoading(false);
        }
      })
    );
  };

  const deleteSection44ADAApi = async (id) => {
    setLoading(true);
    dispatch(
      deleteIncome44ADA({ id }, (e) => {
        if (e.status == 200) {
          setOptionData({
            ...optionData,
            businessProfessionIncome: {
              ...optionData.businessProfessionIncome,
              professionalIncome: null,
            },
          });
          setLoading(false);
        } else {
          // toast.error(e.message);
          setLoading(false);
        }
      })
    );
  };

  const deleteSection44ADApi = async (id) => {
    setLoading(true);
    dispatch(
      deleteIncome44AD({ id }, (e) => {
        if (e.status == 200) {
          setOptionData({
            ...optionData,
            businessProfessionIncome: {
              ...optionData.businessProfessionIncome,
              businessIncome: null,
            },
          });
          setLoading(false);
        } else {
          // toast.error(e.message);
          setLoading(false);
        }
      })
    );
  };

  useEffect(() => {
    getProfessionalBusinessApi();
  }, []);

  //************************************************************************* */
  const [incomeData, setIncomeData] = useState({});
  const { theme } = useSelector((state) => state.platformSetting);
  // const { ItrData } = useSelector((s) => s.itr);
  // const { strings } = useSelector((s) => s.platformSetting);

  // const dispatch = useDispatch();

  const getGrossIncomeApi = () => {
    dispatch(
      getGrossIncomeAction(ItrData.id, (data) => {
        if (data.status == 200) {
          setIncomeData(data.data.grossIncomeDetails);
        }
      })
    );
  };
  useEffect(() => {
    getGrossIncomeApi();
  }, []);

  return (
  <Root theme={theme}>
    <SalaryIncome
      getData={incomeData}
      handleNext={() => handleNext()}
      handleClick={() => {}}
      handleRefresh={() => getGrossIncomeApi()}
    />

    
    {incomeData && incomeData?.grossSalary &&    
    <div className="box_heading">
      <h4 style={{color:theme.primary_txt}}> Gross Salary</h4>
      <div>
      <h4 style={{color:theme.primary_txt}}>{`${strings.label.rupee} ${incomeData?.grossSalary}` }</h4>
      </div>
    </div>
    }
    <ProfessionalIncome/>
    <CapitalGainIncome/>
    <OtherSourceIncome/>
    <HousePropertyIncome/>
  </Root>
  )
}

const Root = styled.div`
  /* .custome{
    
  }
  margin-top: 8px;
  background-color: ${(p) => p.theme.secandary_bg};
  color:${(p) => p.theme.primary_txt};
  border:1px solid ${(p) => p.theme.border_clr};
  border-radius: 10px; */
  .box_heading {
    display: flex;
    width: 100%;
    background-color: ${(p) => p.theme.secandary_bg};
    gap:18px;
    align-items: center;
    justify-content: space-between;
    padding: 18px;
    cursor: pointer;
    button {
      background-color: ${(p) => addOpacity(p.theme.primary_bg, 0.5)};
      border-radius: 50%;
      padding: 6px;
      border: none;
      font-size: 16px;
      transition:all 0.5s;
      &.active {
      transform: rotate(180deg);
    }
    }
  }
`
