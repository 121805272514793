import React, { useState } from "react";
import { FaAngleDown } from "react-icons/fa6";
import styled from "styled-components";
import { addOpacity } from "../../utils/helpers";
import { useSelector } from "react-redux";
import { BiRupee } from "react-icons/bi";
import Label from "../Inputs/Label";
export default function CurrencyAccordianBox({ title,inputBox, description, children ,handleClose}) {
    const [dropdownTab, setDropdownTab] = useState(false);

    const { theme } = useSelector((state) => state.platformSetting);
  return (
    <Root theme={theme}>
      <div className="box_title">
        <label>{title}</label>
        
        <div className="currency_field">
        {inputBox ? inputBox :  <div className="input_field">
        <span><BiRupee /></span>
          <input
            type="number"
            placeholder=''
          />
        </div>}
      
          <button onClick={() => {setDropdownTab(!dropdownTab)}} style={{color:theme.primary_txt}} className={dropdownTab == true ?'active ml-2':'ml-2'}> <FaAngleDown/> </button>
        </div>
      </div>
      {dropdownTab && <div className="box_section">{children}</div>}
    </Root>
  );
}
const Root = styled.section`
  margin-top: 8px;
  background-color: ${(p) => p.theme.secandary_bg};
  border-radius: 10px;
  color:${(p) => p.theme.primary_txt};
  .box_title {
    display:flex;
    align-items:center;
    gap:10px;
    > *{
      flex:1;
    }
    .currency_field{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    button {
      background-color: ${(p) => addOpacity(p.theme.primary_bg, 0.5)};
      border-radius: 50%;
      padding: 6px;
      border: none;
      font-size: 16px;
      transition:all 0.5s;
      &.active {
      transform: rotate(180deg);
    }
    }
  }
  .box_section{
    margin-top:10px;
    border: 1px solid ${(p) => p.theme.border_clr};
    border-radius: 12px;
    padding:10px;
    /* background-color: ${(p) => addOpacity(p.theme.primary_bg, 0.1)}; */
  }
  .input_field {
    position: relative;
    margin: 8px 0px;
    display:flex;
    width: 100%;
    align-items: center;
    border-radius: 12px;
    /* border: 1px solid ${(p) =>p.theme.border_clr}; */
    span{
        padding: 11px;
        font-size: 28px;
        background: ${(p) => addOpacity(p.theme.primary_bg,0.2)};
        border-radius: 12px 0px 0px 12px;
        color: ${(p) => p.theme.primary_txt};
    }
    input{
        padding: 12px;
        border-radius: 0px 12px 12px 0px;
        border: none;
        width:100%;
        border: 1px solid ${(p) =>p.theme.border_clr};
        color: ${(p) => p.theme.primary_txt};
        background-color:transparent;
        &::placeholder {
          color: ${(p) => addOpacity(p.theme.primary_txt , 0.5)};
        font-weight: 400;
        font-size: 14px;
      }
    }
  }
`;
